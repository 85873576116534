import { isPlatformBrowser, PlatformLocation, Location } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, Data } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Constant } from './constants/constant';
import { AnalyticService } from './services/analytic-service';
import { ServiceWorkerService } from './services/service-worker.service';
import { UrlService } from './services/url-service.service';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PushmsgService } from 'src/app/services/pushmsg.service';
import { ShareChatTrackingService } from './services/share-chat-tracking.service';
import { NewsletterPopupService } from './services/newsletter-popup.service';
import { WssService } from 'src/app/services/wss.service';
import { ScreenRecordingService } from './services/screen-recording.service';
import { CountryLocationService } from './services/country-location.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  //@ViewChild('dialogRef')
  public serverPath = environment.commonImageApi + "logo/logo.png";
  public serverPath4 = environment.commonImageApi + "favImage/fav.png";
  private routerSubscription: Subscription;
  //dialogRef!: TemplateRef<any>;
  @ViewChild('dialogRef') dialogRef: TemplateRef<any>;

  newUrl: any;
  url: any;
  url1: any;
  subscription: any;
  browserRefresh: boolean;
  refresh: boolean;
  previousUrl: string = null;
  currentUrl: string = null;
  //pushPayload: INotificationPayload;
  hasPermission: any;
  token: any;
  data: any;

  showPopup = false;
  showBackToTop: boolean = false;
  countryCode: any;

  constructor(
    public router: Router,
    private location: Location,
    public platform1: PlatformLocation,
    private urlService: UrlService,
    private swUpdate: ServiceWorkerService,
    private analyticService: AnalyticService,
    private title: Title,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    public dialog: MatDialog,
    private push_msg: PushmsgService,
    private _activatedRoute: ActivatedRoute,
    private shareChatTrackingService: ShareChatTrackingService,
    private newsletterPopupService: NewsletterPopupService,
    private cdr: ChangeDetectorRef,
    private wss: WssService,
    public screenRecordDetectionService: ScreenRecordingService,
    private countryLocationService:CountryLocationService
  ) {
    this.swUpdate.checkForUpdates();

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.newUrl = event.url;
      });

    this.newUrl = this.router.url;

  }

  getUserLocationData() {

  
     
    this.countryCode=this.countryLocationService.getCountryCode()
  }


  ngOnInit(): void {

    let countryData=localStorage.getItem(Constant.COUNTRY_LOCATION);
 
    if(countryData==null || countryData==undefined ){
      this.getUserLocationData();
    }
   

    this.newsletterPopupService.getPopupVisibility().subscribe((isVisible) => {
      this.showPopup = isVisible;
    });

    //this.screenRecordDetectionService.detectScreenRecording();

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.urlService.setPreviousUrl(this.previousUrl);
      if (this.currentUrl.includes("/pr?sid=")) {

        let url = this.currentUrl.split("sid=")[1];

        this.urlService.setBreadCrumpId(url);
      }

      if (this.previousUrl == "/orders" && this.currentUrl == "/checkout") {
        this.router.navigate(['/']);
      }

      if (localStorage.getItem(Constant.GOOGLE_ANALYTICS_ENABLED) != undefined && localStorage.getItem(Constant.GOOGLE_ANALYTICS_ENABLED) != '') {
        this.analyticService.pageView(event.url, this.title.getTitle())
      }

      if (localStorage.getItem('shareChat') != undefined && localStorage.getItem('shareChat') != '') {

        this.shareChatTrackingService.initializeTracking
          (localStorage.getItem('shareChat'));
      }


    });
    this.startStopTimer();
    this.wss.connectWithSocketIOServer();
  }

  @HostListener('window:scroll', ['$event'])
  onScrollEvent($event: Event): void {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const box = document.getElementById('box');

    if (box) {
      if (scrollTop >= 200) {
        box.classList.add("header-fixed");
      } else {
        box.classList.remove("header-fixed");
      }
    }

    this.showBackToTop = scrollTop > 300;
    this.cdr.detectChanges(); // Ensures Angular detects the change and updates the view
  }



  ngAfterViewInit() {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.startStopTimer();
        if (isPlatformBrowser(this.platformId)) {
          //  window.scrollTo(0,0);
        }
      }
    })

    // const script = document.createElement('script');
    // script.src = 'https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1';
    // script.async = true;
    // script.defer = true;
    // script.onload = () => {
    // };
    // document.body.appendChild(script);

    // this.appendDfMessenger();
  }


  appendDfMessenger() {
    // Create the <df-messenger> element
    const dfMessenger = document.createElement('df-messenger');

    // Set attributes
    dfMessenger.setAttribute('intent', 'WELCOME');
    dfMessenger.setAttribute('chat-title', 'dev-calsoft');
    dfMessenger.setAttribute('agent-id', 'cc0e330c-8185-4e82-b1bc-c51e1dd95577');
    dfMessenger.setAttribute('language-code', 'en');

    // Append the element to the body of the document
    document.body.appendChild(dfMessenger);
  }

  private timeoutId: any;

  startStopTimer() {
    // Clear previous timeout (if any)
    clearTimeout(this.timeoutId);

    // Start a new timeout for 5 minutes
    this.timeoutId = setTimeout(() => {
      window.stop();
    }, 45000);
  }




  @HostListener('window:popstate') backbuttonpressed() {
    const currentUrl = this.router.url;
    if (currentUrl === "/home" && navigator['app']) {
      navigator['app'].exitApp();
    }
  }



  ngOnDestroy() {
    // Unsubscribe from the router events and clear the timeout
    this.routerSubscription.unsubscribe();
    clearTimeout(this.timeoutId);
  }

  openTempDialog(payload) {
    console.log(JSON.stringify(payload));
    //alert('Notification: '+ payload.title + ' --- '+ payload.body)

    const mydata = this.dialog.open(this.dialogRef, { data: payload });
    mydata.afterClosed().subscribe((res) => {

      // Data back from dialog
      console.log({ res });
    });

  }


  @HostListener('document:contextmenu', ['$event'])
  onRightClick(event: MouseEvent) {
    if (this.newUrl.includes('/course-detail/')) {
      event.preventDefault();
    }
  }


  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.newUrl.includes('/course-detail/')) {
      if (
        event.key === 'F12' ||
        (event.ctrlKey && event.shiftKey && event.key === 'I') ||
        (event.ctrlKey && event.key === 'u') ||
        (event.metaKey && event.altKey && event.key === 'r') ||
        event.key === 'PrintScreen' // Block Print Screen key
      ) {
        event.preventDefault();
      }
    }
  }

}
