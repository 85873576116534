import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constant } from '../constants/constant';
import CONSTANTS from '../pages/payment-gate-way/paytm/constants';

@Injectable({
  providedIn: 'root'
})
export class CountryLocationService {

  private url = 'https://ipapi.co//json/';

  constructor(private http: HttpClient) { }

  getCountryCode(){
    return  getConfig() ;
  }
}

// function getConfig() {
//   const url = "http://ip-api.com/json";

//   fetch(url)
//     .then(response => {
//       if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }
      
//       console.log(response);
      
//       localStorage.setItem(Constant.COUNTRY_LOCATION,JSON.stringify(response))
      
//       return response;
//     })
//     .then(data => {
//       console.log('Configuration data:', data.body);
//       // Use the fetched data here
//     })
//     .catch(error => {
//       console.error('Error fetching configuration:', error);
//     });
// }





function getConfig() {
  let config;


    let request = new XMLHttpRequest();
    try {


      let url = "https://ipapi.co//json/";
      

      request.open('GET', url, false);  // `false` makes the request synchronous
      request.setRequestHeader('Content-Type', 'application/json');

  


      request.send(null);

      // console.log(request);

      if (request.status === 200) {
        //  console.log(request.responseText);
        config = request.responseText;
       let obj = JSON.parse(request.responseText);
       console.log(obj);
       localStorage.setItem(Constant.COUNTRY_LOCATION,JSON.stringify(obj))
       
        
      }

    } catch (e) {
      console.error('environment:getConfig: unable to get api key : ', e);
    }

  


  return config;
}
