<div class="container">
<div *ngIf="display">
    <div *ngIf="url!='/home'">
        <mat-toolbar-row fxLayoutAlign="start center" style="height: auto;">

            <div fxLayout="row" fxFlex="10" fxLayoutGap="10px">
                <section class="padding-10">

                    <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

                </section>
            </div>

            <div fxLayout="row" fxLayoutGap="10px" fxFlex="75">

                <calsoft-Menu [color]="false" [data]="data" [type]="'one'" style="color: #000000; z-index: 1000;"
                    class="menu" [categoryArray]="item"></calsoft-Menu>

            </div>

            <div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayoutAlign="end center">
                <div *ngIf="data.disableSearchInHeader==0">
                    <div *ngIf="url != '/cart'  && url !== '/checkout' && url !=='/checkout/type'">
                        <mat-icon style=" cursor: pointer;" fxLayoutAlign="center center" *ngIf="!displaySearchBar"
                            (click)="showSearchBar()">search</mat-icon>
                    </div>


                    <div *ngIf="displaySearchBar" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row"
                        fxLayoutAlign="start center" fxFlex fxFlex.gt-sm="350px">
                        <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                            [searchbarborderRadius]="'15px'"></calsoft-search>
                    </div>
                </div>

            </div>

            <div fxFlex="5">

            </div>



        </mat-toolbar-row>
    </div>

    <div *ngIf="url=='/home'" class="header">
        <div class="header-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="logo">
                <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

            </div>
            <div fxLayout="row">
                <mat-toolbar-row fxHide fxShow.gt-sm class="container" fxLayout="row" fxLayout.xs="row"
                    fxLayoutAlign="space-between center">
                    <calsoft-Menu [color]="true" [data]="data" [type]="'one'" style="color: #000000;" class="menu"
                        [categoryArray]="item"></calsoft-Menu>
                </mat-toolbar-row>

                <!-- <calsoft-cart-icon [data]="data"  fxFlex></calsoft-cart-icon> -->
            </div>

        </div>
    </div>

</div>
</div>

<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header3>
    </div>

    <div *ngIf="mobileHeaderType==3">
        <calsoft-mobile-header4 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header4>
    </div>

    <div *ngIf="mobileHeaderType==4">
        <calsoft-mobile-header5 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        </calsoft-mobile-header5>
    </div>

    <div *ngIf="mobileHeaderType==5">
        <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header6>
    </div>
    <div *ngIf="mobileHeaderType==6">
        <calsoft-mobile-header7 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header7>
    </div>
    <div *ngIf="mobileHeaderType==7">

        <calsoft-mobile-header8 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header8>
    </div>

</div>